// FileSelect.js
import React from "react";
import "./Loader.css"

export default function Loader() {

    
    
    
    return (
        <div className="loader-container">
            <div className="loader rounded-lg">
                
                <img src="logoEditofOpacity.png" alt='logo'></img>
                
            </div>
        </div>
    );
}
